'use strict';

angular.module('informaApp')
    .directive('setTooltip', ['$timeout', TooltipController]);


function TooltipController($timeout) {
    return {
        restrict: 'A',
        setTooltip: function(element, attr) {
                element.find('.inf-tooltip').remove();

                var tooltipBadge = angular.element('<sup class="inf-tooltip"><i class="glyphicon glyphicon-info-sign info"></i></sup>');
                var tooltipText = attr.setTooltip;

                if (tooltipText) {
                    element.append(tooltipBadge);
                    element.attr('title', tooltipText)
                }

        },
        scope: {
            setTooltip: '@'
        },
        link: function (scope, element, attr) {
            var self = this;

            $timeout(function() {
                self.setTooltip(element, attr);
            });

            scope.$watch('setTooltip', function () {
                self.setTooltip(element, attr);
            }, true)
        }
    }
}